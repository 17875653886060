// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vjzzQVSHw", "M8ChuWkpp"];

const serializationHash = "framer-vwa9i"

const variantClassNames = {M8ChuWkpp: "framer-v-1m7tlfa", vjzzQVSHw: "framer-v-17xdvb1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "vjzzQVSHw", "Variant 2": "M8ChuWkpp"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "vjzzQVSHw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vjzzQVSHw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17xdvb1", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vjzzQVSHw"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({M8ChuWkpp: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1u1yzpe"} data-framer-name={"Rec 2"} layoutDependency={layoutDependency} layoutId={"SKP72mbhB"} style={{backgroundColor: "rgb(184, 184, 184)", boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)"}} variants={{M8ChuWkpp: {backgroundColor: "rgb(119, 235, 101)", boxShadow: "0px 1px 2px 0px rgba(120, 235, 101, 0.54)"}}}/><motion.div className={"framer-1id0syj"} data-border data-framer-name={"Rec 1"} layoutDependency={layoutDependency} layoutId={"I30MSxrWt"} style={{"--border-bottom-width": "0.6px", "--border-color": "rgba(0, 0, 0, 0.12)", "--border-left-width": "0.6px", "--border-right-width": "0.6px", "--border-style": "solid", "--border-top-width": "0.6px"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vwa9i.framer-2y8ujq, .framer-vwa9i .framer-2y8ujq { display: block; }", ".framer-vwa9i.framer-17xdvb1 { height: 16px; overflow: visible; position: relative; width: 16px; }", ".framer-vwa9i .framer-1u1yzpe { flex: none; height: 10px; left: calc(50.00000000000002% - 10px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 10px / 2); width: 10px; }", ".framer-vwa9i .framer-1id0syj { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; z-index: 2; }", ".framer-vwa9i[data-border=\"true\"]::after, .framer-vwa9i [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"M8ChuWkpp":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerwgrtOOsrM: React.ComponentType<Props> = withCSS(Component, css, "framer-vwa9i") as typeof Component;
export default FramerwgrtOOsrM;

FramerwgrtOOsrM.displayName = "point";

FramerwgrtOOsrM.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerwgrtOOsrM, {variant: {options: ["vjzzQVSHw", "M8ChuWkpp"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerwgrtOOsrM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})